import { JsonProperty, Serializable } from "@anna-money/anna-web-lib";
import { Datetime } from "types/date";

export enum CompanyProduct {
  VirtualOffice = "VIRTUAL_OFFICE",
  Taxes = "TAXES",
  VatRegistration = "VAT_REGISTRATION",
  ConfirmationStatement = "CONFIRMATION_STATEMENT",
  CancellationCover = "CANCELLATION_COVER",
  PayeRegistration = "PAYE_REGISTRATION",
  SameDayRegistration = "SAME_DAY_REGISTRATION",
  DirectoryManagement = "DIRECTOR_MANAGEMENT",
  // AU
  RegisterBusinessName = "REGISTER_BUSINESS_NAME",
  AbnRegistration = "ABN_REGISTRATION",
}

export enum VirtualOfficeBillingPeriod {
  Monthly = "MONTHLY",
  Yearly = "YEARLY",
}

export enum RegisterBusinessNamePeriods {
  ThreeYears = "3 years",
  OneYear = "1 year",
  None = "None",
}

export const getProductDetailsType = (product: CompanyProduct): Function | undefined => {
  switch (product) {
    case CompanyProduct.VirtualOffice:
      return VirtualOfficeProductDetailsData;
    default:
      return undefined;
  }
};

export type DefaultCompanyProductDetailsData = Record<string, any>;

@Serializable()
export class VirtualOfficeProductDetailsData {
  constructor(@JsonProperty({ type: VirtualOfficeBillingPeriod }) readonly billingPeriod: VirtualOfficeBillingPeriod) {}
}

@Serializable()
export class CompanyProductData {
  constructor(
    @JsonProperty({ type: CompanyProduct }) readonly product: CompanyProduct,
    @JsonProperty({ type: Object }) readonly details: DefaultCompanyProductDetailsData,
  ) {}
}

@Serializable()
export class CompanyProductsData {
  constructor(@JsonProperty({ type: CompanyProductData, isArray: true }) readonly items: CompanyProductData[]) {}
}

@Serializable()
export class CompanyProductChangeData {
  constructor(
    @JsonProperty({ type: CompanyProduct }) readonly product: CompanyProduct,
    @JsonProperty({ type: Object, required: false }) readonly details: Nullable<Record<string, any>>,
  ) {}
}

@Serializable()
export class FormationExpressStateData {
  constructor(
    @JsonProperty({ type: Boolean }) readonly featureAvailable: boolean,
    @JsonProperty({ type: Datetime, required: false }) readonly currectTimestamp: Nullable<Datetime>,
    @JsonProperty({ type: Datetime, required: false }) readonly availableUntil: Nullable<Datetime>,
  ) {}
}
