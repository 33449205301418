import { FormSubmitMethod } from "helpers/decorators/formSubmitMethod";
import type { AbnFormValues } from "helpers/forms/acn/abnTypes";
import type { CompanyBusinessActivitiesStore } from "services/companyBusinessActivities/au/companyBusinessActivitiesStore";
import { FormError } from "types/errors";
import type { FormSubmitResult } from "types/form";

export type CompanyBusinessActivityFormValues = Pick<AbnFormValues, "businessActivities">;

export class MainBusinessActivityFormStore {
  constructor(private readonly _companyBusinessActivitiesStore: CompanyBusinessActivitiesStore) {}

  @FormSubmitMethod()
  async onSubmit(data: CompanyBusinessActivityFormValues): Promise<FormSubmitResult> {
    if (!data.businessActivities?.length) {
      throw new FormError("No business activities value");
    }

    const mainBusinessActivity = data.businessActivities[0];

    if (!mainBusinessActivity.address) {
      throw new FormError("No business activity address value");
    }

    await this._companyBusinessActivitiesStore.updateActivity(
      mainBusinessActivity.code,
      mainBusinessActivity.description,
      mainBusinessActivity.address,
    );
  }
}
