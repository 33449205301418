import { ensured } from "helpers/value";
import type { BusinessActivitiesClient } from "services/businessActivities/businessActivitiesClient";
import { type BusinessActivitiesStore } from "services/businessActivities/businessActivitiesStore";
import type { BusinessActivityData } from "services/businessActivities/businessActivitiesTypes";
import { BusinessActivitiesSelectStoreBase } from "services/businessActivitiesSelect/businessActivitiesSelectStoreBase";
import { type CompanyBusinessActivitiesStore } from "services/companyBusinessActivities/gb/companyBusinessActivitiesStore";

export class BusinessActivitiesSelectStore extends BusinessActivitiesSelectStoreBase {
  constructor(
    protected readonly _businessActivitiesClient: BusinessActivitiesClient,
    protected readonly _businessActivitiesStore: BusinessActivitiesStore,
    private readonly _companyBusinessActivitiesStore: CompanyBusinessActivitiesStore,
  ) {
    super(_businessActivitiesClient, _businessActivitiesStore);

    this._setActivities(this._businessActivitiesStore.activities);
  }

  get deletingBlocked(): boolean {
    return this._companyBusinessActivitiesStore.activities.length === 1;
  }

  async createActivity(activityId: string): Promise<void> {
    await this._processAction(async () => {
      await this._companyBusinessActivitiesStore.createActivity(activityId);
      return this.activities;
    });
  }

  async deleteActivity(activityId: string): Promise<void> {
    await this._processAction(async () => {
      await this._companyBusinessActivitiesStore.deleteActivity(activityId);
      return this.activities;
    });
  }

  protected _setActivities(activities: BusinessActivityData[]): void {
    this.activities = activities.filter(
      (activity) => !this._companyBusinessActivitiesStore.hasActivityWithId(ensured(activity.id)),
    );
  }
}
