import { type ConsentFormValues } from "helpers/forms/consentTypes";
import { type DirectorStepFormValues } from "helpers/forms/directorTypes";
import { type CompanyFormValues } from "helpers/forms/incorporation/companyTypes";
import { type ParticularsFormValues } from "helpers/forms/particularsTypes";
import { type AnalyticsManager } from "services/analytics/analyticsManager";
import type { BusinessActivitiesStore } from "services/businessActivities/businessActivitiesStore";
import { type CompanyStore } from "services/company/companyStore";
import { type CompanyAddressStore } from "services/companyAddress/gb/companyAddressStore";
import type { CompanyBusinessActivitiesStore } from "services/companyBusinessActivities/gb/companyBusinessActivitiesStore";
import { type CompanyProductsStore } from "services/companyProducts/companyProductsStore";
import { type ConsentStore } from "services/consent/consentStore";
import { type DirectorEntity, type DirectorStore } from "services/director/directorStore";
import { type FormationExpressStore } from "services/formationExpress/formationExpressStore";
import { type IncorporationStore } from "services/incorporation/incorporationStore";
import { type IssuesStore } from "services/issues/issuesStore";
import { type PaymentMethodsStore } from "services/paymentMethods/paymentMethodsStore";
import { type PricingPlanStore } from "services/pricingPlan/gb/pricingPlanStore";
import { RegisterFormStoreBase } from "services/registerForm/registerFormStoreBase";
import { type CompanyStepEntity, type CompanyStepStore } from "services/stepStore/gb/companyStepStore";
import { type ParticularsStepStore } from "services/stepStore/gb/particularsStepStore";
import { type SumsubApplicationStore } from "services/sumsub/sumsubApplicationStore";
import type { UserStore } from "services/user/userStore";
import { FlowType } from "services/user/userTypes";
import { IncoroporationFormStep } from "./constants";
import { type IncorporationRegisterFormStepsStore } from "./incorporationRegisterFormStepsStore";

export class IncorporationRegisterFormStore extends RegisterFormStoreBase<IncoroporationFormStep> {
  protected _flowType: FlowType = FlowType.Incorporation;

  constructor(
    private readonly _consentStore: ConsentStore,
    private readonly _directorStore: DirectorStore,
    private readonly _companyStore: CompanyStore,
    private readonly _companyAddressStore: CompanyAddressStore,
    private readonly _businessActivitiesStore: BusinessActivitiesStore,
    private readonly _companyBusinessActivitiesStore: CompanyBusinessActivitiesStore,
    private readonly _companyProductsStore: CompanyProductsStore,
    private readonly _particularsStepStore: ParticularsStepStore,
    private readonly _paymentMethodsStore: PaymentMethodsStore,
    private readonly _sumsubStore: SumsubApplicationStore,
    private readonly _formationExpressStore: FormationExpressStore,
    private readonly _pricingPlanStore: PricingPlanStore,
    protected readonly _userStore: UserStore,
    protected readonly _analyticsManager: AnalyticsManager,
    protected readonly _registerFormStepsStore: IncorporationRegisterFormStepsStore,
    protected readonly _incorporationStore: IncorporationStore,
    protected readonly _companyIssuesStore: IssuesStore,
    protected readonly _companyStepStore: CompanyStepStore,
  ) {
    super(_registerFormStepsStore, _userStore, _incorporationStore, _companyIssuesStore, _analyticsManager);
  }

  get hideBackButton(): boolean {
    return this._stepsStore.activeStep.type === IncoroporationFormStep.CHARGE || super.hideBackButton;
  }

  protected async _init(): Promise<void> {
    await this._companyStore.init();

    await Promise.all([
      this._consentStore.init(),
      this._companyAddressStore.init(),
      this._businessActivitiesStore.init(),
      this._companyBusinessActivitiesStore.init(),
      this._companyProductsStore.init(),
      this._pricingPlanStore.init(),
      this._directorStore.init(),
      this._sumsubStore.init(),
      this._companyIssuesStore.init(),
      this._incorporationStore.init(),
      this._paymentMethodsStore.init(),
      this._formationExpressStore.init(),
    ]);
  }

  protected _getRelevantStep(): IncoroporationFormStep {
    const { address } = this._companyAddressStore;
    const { mainDirector } = this._directorStore;
    const { sumsubApplication } = this._sumsubStore;

    if (sumsubApplication) {
      return IncoroporationFormStep.IDENTITY;
    }

    if (mainDirector) {
      return IncoroporationFormStep.DIRECTOR;
    }

    // we created constants during sign-up
    // having address means customer filled something
    // other things - start from 1 step (intro)
    if (address) {
      return IncoroporationFormStep.COMPANY;
    }

    return IncoroporationFormStep.INTRO;
  }

  protected async _updateStepData(data?: unknown, entity?: unknown): Promise<void> {
    switch (this._registerFormStepsStore.activeStep.type) {
      case IncoroporationFormStep.CONSENT:
        await this._consentStore.updateConsents(data as ConsentFormValues);
        break;
      case IncoroporationFormStep.COMPANY:
        await this._companyStepStore.updateEntity(data as CompanyFormValues, entity as CompanyStepEntity);
        break;
      case IncoroporationFormStep.DIRECTOR:
        await this._directorStore.updateMainDirector(data as DirectorStepFormValues, entity as DirectorEntity);
        break;
      case IncoroporationFormStep.SHARES:
        await this._particularsStepStore.updateShares(data as ParticularsFormValues);
        break;
      case IncoroporationFormStep.CHARGE:
        await this._createIncorporation();
        break;
    }
  }
}
