import { FormSubmitMethod } from "helpers/decorators/formSubmitMethod";
import { type CompanyFormValues } from "helpers/forms/acn/companyTypes";
import type { CompanyStore } from "services/company/companyStore";
import { FormError } from "types/errors";
import type { FormSubmitResult } from "types/form";

export type CompanyNameFormValues = Pick<CompanyFormValues, "name" | "namePostfix">;

export class CompanyNameFormStore {
  constructor(private readonly _companyStore: CompanyStore) {}

  @FormSubmitMethod()
  async onSubmit(values: CompanyNameFormValues): Promise<FormSubmitResult> {
    if (!values.name || !values.namePostfix) {
      throw new FormError("No company name or name postfix selected");
    }

    await this._companyStore.updateName(values.name, values.namePostfix);
  }
}
