import { makeAutoObservable } from "mobx";

import { addressFormToCreateData, addressFormToUpdateData } from "helpers/forms/address";
import type { AddressFormValue } from "helpers/forms/addressTypes";
import { type AddressClient, AddressKey } from "services/address/addressClient";
import { type AddressData } from "services/address/addressTypes";
import type { CompanyStore } from "services/company/companyStore";

export class CompanyBusinessActivitiesStore {
  private _address?: AddressData;

  constructor(
    private readonly _companyStore: CompanyStore,
    private readonly _addressClient: AddressClient,
  ) {
    makeAutoObservable(this);
  }

  get address(): AddressData | undefined {
    return this._address;
  }

  private set address(value: AddressData) {
    this._address = value;
  }

  async init(): Promise<void> {
    if (this._companyStore.company?.id) {
      this._address = await this._addressClient.getAddress(
        AddressKey.BusinessActivityCompany,
        this._companyStore.company.id,
      );
    }
  }

  async updateActivity(
    activityCode: string,
    activityDescription: string,
    addressData: AddressFormValue,
  ): Promise<void> {
    if (this.address) {
      this.address = await this._addressClient.updateAddress(this.address.id, addressFormToUpdateData(addressData));
    } else {
      this.address = await this._addressClient.createAddress(
        addressFormToCreateData(addressData, { businessCompanyId: this._companyStore.getCompany().id }),
      );
    }

    await this._companyStore.updateMainBusinessActivity(activityCode, activityDescription, this.address.id);
  }
}
