import type { AbnFormValues } from "helpers/forms/acn/abnTypes";
import { type CompanyFormValues } from "helpers/forms/acn/companyTypes";
import type { ConsentFormValues } from "helpers/forms/consentTypes";
import { type DirectorStepFormValues } from "helpers/forms/directorTypes";
import { type AnalyticsManager } from "services/analytics/analyticsManager";
import type { BusinessActivitiesStore } from "services/businessActivities/businessActivitiesStore";
import { type CompanyStore } from "services/company/companyStore";
import { type CompanyAddressStore } from "services/companyAddress/au/companyAddressStore";
import type { CompanyBusinessActivitiesStore } from "services/companyBusinessActivities/au/companyBusinessActivitiesStore";
import { type CompanyProductsStore } from "services/companyProducts/companyProductsStore";
import type { ConsentStore } from "services/consent/consentStore";
import { type DirectorEntity, type DirectorStore } from "services/director/directorStore";
import { type IncorporationStore } from "services/incorporation/incorporationStore";
import { type IssuesStore } from "services/issues/issuesStore";
import { type PaymentMethodsStore } from "services/paymentMethods/paymentMethodsStore";
import type { PricingPlanStore } from "services/pricingPlan/au/pricingPlanStore";
import { RegisterFormStoreBase } from "services/registerForm/registerFormStoreBase";
import { AbnStepEntity, type AbnStepStore } from "services/stepStore/au/abnStepStore";
import { type CompanyAcnStepEntity, type CompanyAcnStepStore } from "services/stepStore/au/companyAcnStepStore";
import type { SumsubApplicationStore } from "services/sumsub/sumsubApplicationStore";
import type { UserStore } from "services/user/userStore";
import { FlowType } from "services/user/userTypes";
import { type AcnRegisterFormStepsStore } from "./acnRegisterFormStepsStore";
import { AcnFormStep } from "./constants";

export class AcnRegisterFormStore extends RegisterFormStoreBase<AcnFormStep> {
  protected _flowType: FlowType = FlowType.AcnAbn;

  constructor(
    private readonly _directorStore: DirectorStore,
    private readonly _companyStore: CompanyStore,
    private readonly _companyAddressStore: CompanyAddressStore,
    private readonly _companyProductsStore: CompanyProductsStore,
    private readonly _paymentMethodsStore: PaymentMethodsStore,
    private readonly _consentStore: ConsentStore,
    private readonly _companyBusinessActivitiesStore: CompanyBusinessActivitiesStore,
    private readonly _businessActivitiesStore: BusinessActivitiesStore,
    private readonly _pricingPlanStore: PricingPlanStore,
    protected readonly _userStore: UserStore,
    protected readonly _sumsubApplicationStore: SumsubApplicationStore,
    protected readonly _analyticsManager: AnalyticsManager,
    protected readonly _registerFormStepsStore: AcnRegisterFormStepsStore,
    protected readonly _incorporationStore: IncorporationStore,
    protected readonly _companyIssuesStore: IssuesStore,
    protected readonly _companyStepStore: CompanyAcnStepStore,
    protected readonly _abnStepStore: AbnStepStore,
  ) {
    super(_registerFormStepsStore, _userStore, _incorporationStore, _companyIssuesStore, _analyticsManager);
  }

  protected async _init(): Promise<void> {
    await this._companyStore.init();

    await Promise.all([
      this._consentStore.init(),
      this._companyAddressStore.init(),
      this._directorStore.init(),
      this._pricingPlanStore.init(),
      this._companyProductsStore.init(),
      this._sumsubApplicationStore.init(),
      this._companyIssuesStore.init(),
      this._incorporationStore.init(),
      this._paymentMethodsStore.init(),
      this._companyBusinessActivitiesStore.init(),
      this._businessActivitiesStore.init(),
    ]);
  }

  protected _getRelevantStep(): AcnFormStep {
    if (this._sumsubApplicationStore.sumsubApplication) {
      return AcnFormStep.IDENTITY;
    }

    if (this._directorStore.mainDirector) {
      return AcnFormStep.DIRECTOR;
    }

    if (this._companyStore.company?.stateOfRegistration) {
      return AcnFormStep.COMPANY;
    }

    return AcnFormStep.HOW_IT_WORKS;
  }

  protected async _updateStepData(data: unknown, entity?: unknown): Promise<void> {
    switch (this._registerFormStepsStore.activeStep.type) {
      case AcnFormStep.CONSENT:
        await this._consentStore.updateConsents(data as ConsentFormValues);
        break;
      case AcnFormStep.COMPANY:
        await this._companyStepStore.updateEntity(data as CompanyFormValues, entity as CompanyAcnStepEntity);
        break;
      case AcnFormStep.ABN:
        await this._abnStepStore.updateEntity(data as AbnFormValues, entity as AbnStepEntity);
        break;
      case AcnFormStep.DIRECTOR: {
        await this._directorStore.updateMainDirector(data as DirectorStepFormValues, entity as DirectorEntity);
        break;
      }
      case AcnFormStep.CHARGE: {
        await this._createIncorporation();
        break;
      }
    }
  }
}
