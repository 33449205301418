import { makeObservable, observable } from "mobx";

import type { BusinessActivitiesClient } from "services/businessActivities/businessActivitiesClient";
import { type BusinessActivitiesStore } from "services/businessActivities/businessActivitiesStore";
import type { BusinessActivityData } from "services/businessActivities/businessActivitiesTypes";
import type { LazyLoadableState } from "types/loadableState";

type BusinessActivitiesSelectState = LazyLoadableState<"success">;

export abstract class BusinessActivitiesSelectStoreBase {
  private _state: BusinessActivitiesSelectState = "idle";
  private _activities: BusinessActivityData[] = [];

  protected constructor(
    protected readonly _businessActivitiesClient: BusinessActivitiesClient,
    protected readonly _businessActivitiesStore: BusinessActivitiesStore,
  ) {
    makeObservable(this, {
      _state: observable,
      _activities: observable,
    } as any);
  }

  protected abstract _setActivities(activities: BusinessActivityData[]): void;

  get state(): BusinessActivitiesSelectState {
    return this._state;
  }

  private set state(value: BusinessActivitiesSelectState) {
    this._state = value;
  }

  get activities(): BusinessActivityData[] {
    return this._activities;
  }

  protected set activities(value: BusinessActivityData[]) {
    this._activities = value;
  }

  async searchActivities(query: string): Promise<BusinessActivityData[]> {
    if (!query) {
      this.reset();
      return this.activities;
    }

    await this._processAction(async () => {
      return await this._businessActivitiesClient.getBusinessActivities(query);
    });

    return this.activities;
  }

  reset(): void {
    this.activities = this._businessActivitiesStore.activities;
  }

  protected async _processAction(action: () => Promise<BusinessActivityData[]>): Promise<void> {
    if (this.state === "loading") {
      return;
    }
    this.state = "loading";
    this._setActivities(await action());
    this.state = "success";
  }
}
