import { makeAutoObservable } from "mobx";

import { type CompanyClient } from "services/company/companyClient";
import {
  AustralianState,
  CompanyAbnStartDateUpdateData,
  CompanyAgriculturalPropertyOperationsUpdateData,
  type CompanyCountry,
  CompanyCountryUpdateData,
  CompanyCreateData,
  type CompanyData,
  CompanyMainBusinessActivityUpdateData,
  CompanyNamePostfix,
  CompanyNameUpdateData,
  CompanyPoaUpdateData,
  CompanyPricingPlanUpdateData,
  CompanySharesUpdateData,
  CompanyStateUpdateData,
  type CompanyUpdateData,
  CompanyUseVirtualOfficeUpdateData,
} from "services/company/companyTypes";
import type { FeatureStore } from "services/feature/featureStore";
import { type PricingPlan } from "services/pricingPlan/pricingPlanTypes";

export interface CompanyState {
  company?: CompanyData;
}

export class CompanyStore {
  private _state: CompanyState = {};

  constructor(
    private readonly _companyClient: CompanyClient,
    private readonly _featureStore: FeatureStore,
  ) {
    makeAutoObservable(this);
  }

  get state(): CompanyState {
    return this._state;
  }

  set state(value: CompanyState) {
    this._state = value;
  }

  get company(): CompanyData | undefined {
    return this.state.company;
  }

  private set company(company) {
    this.state.company = company;
  }

  async init(): Promise<void> {
    this.company = await this._companyClient.getCompany();
  }

  getCompany(): CompanyData {
    if (!this.company) {
      throw new Error("Company not found");
    }

    return this.company;
  }

  async updatePoa(poaFileId: string): Promise<void> {
    this.company = await this._companyClient.updateCompany(this.getCompany().id, new CompanyPoaUpdateData(poaFileId));
  }

  async updateShares(totalShares: string, totalCapital: string): Promise<void> {
    this.company = await this._companyClient.updateCompany(
      this.getCompany().id,
      new CompanySharesUpdateData(parseInt(totalShares), parseInt(totalCapital)),
    );
  }

  async updateName(name: string, namePostfix?: CompanyNamePostfix): Promise<void> {
    this.company = await this._companyClient.updateCompany(
      this.getCompany().id,
      new CompanyNameUpdateData(name, namePostfix),
    );
  }

  async updateCountry(country?: CompanyCountry): Promise<void> {
    if (!country) {
      return;
    }

    this.company = await this._companyClient.updateCompany(this.getCompany().id, new CompanyCountryUpdateData(country));
  }

  async updatePricingPlan(pricingPlan: PricingPlan): Promise<void> {
    this.company = await this._companyClient.updateCompany(
      this.getCompany().id,
      new CompanyPricingPlanUpdateData(pricingPlan),
    );
  }

  async updateVirtualOffice(useVirtualOffice: boolean): Promise<void> {
    this.company = await this._companyClient.updateCompany(
      this.getCompany().id,
      new CompanyUseVirtualOfficeUpdateData(useVirtualOffice),
    );
  }

  async updateAbnStartDate(startDate: string): Promise<void> {
    this.company = await this._companyClient.updateCompany(
      this.getCompany().id,
      new CompanyAbnStartDateUpdateData(startDate),
    );
  }

  async updateAgriculturalPropertyOperations(value: boolean): Promise<void> {
    this.company = await this._companyClient.updateCompany(
      this.getCompany().id,
      new CompanyAgriculturalPropertyOperationsUpdateData(value),
    );
  }

  async updateCompanyStateOfRegistration(state: AustralianState): Promise<void> {
    this.company = await this._companyClient.updateCompany(this.getCompany().id, new CompanyStateUpdateData(state));
  }

  async updateMainBusinessActivity(
    businessActivityCode: string,
    businessActivityDescription: string,
    businessActivityAddressId: string,
  ): Promise<void> {
    this.company = await this._companyClient.updateCompany(
      this.getCompany().id,
      new CompanyMainBusinessActivityUpdateData(
        businessActivityCode,
        businessActivityDescription,
        businessActivityAddressId,
      ),
    );
  }

  async updateCompany(data: CompanyUpdateData): Promise<void> {
    this.company = await this._companyClient.updateCompany(this.getCompany().id, data);
  }

  async createCompany(name: string, namePostfix: CompanyNamePostfix): Promise<void>;
  async createCompany(name?: string, namePostfix?: CompanyNamePostfix): Promise<void> {
    if (name && namePostfix) {
      this.company = await this._companyClient.createCompany(new CompanyCreateData(name, namePostfix));
    } else {
      this.company = await this._companyClient.createCompany();
    }

    // on company creation backend can set some features to customer, so let's update them
    await this._featureStore.reload();
  }
}
