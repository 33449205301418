import { AnnaErrorBase, CustomError } from "@anna-money/anna-web-lib";
import { isWebticsError, setupWebtics, webticsCall } from "@anna-money/webtics";
import { reaction } from "mobx";
import { type UserStore } from "services/user/userStore";

export interface IAnalyticsManager {
  event: (eventType: string, payload?: object) => void;
  googleEvent: (eventType: string) => void;
}

@CustomError("WebticsError")
class WebticsError extends AnnaErrorBase {
  constructor(message: string, cause: unknown) {
    super(message, { cause });
  }
}

export class AnalyticsManager implements IAnalyticsManager {
  constructor(
    apiUrl: string,
    webticsTrackerUrl: string,
    private readonly _appVersion: string,
  ) {
    setupWebtics(
      webticsTrackerUrl,
      { apiUrl, appName: "formation" },
      {
        errorHandler: (e) => {
          if (!isWebticsError(e, "network")) {
            console.error(new WebticsError("Webtics error", e));
          }
        },
      },
    );
    webticsCall("setContext", { app_version: _appVersion });
  }

  configure(userStore: UserStore): void {
    reaction(
      () => userStore.user,
      () => {
        webticsCall("setAlias", userStore.user.alias);
      },
      { fireImmediately: true },
    );
  }

  event(eventType: string, payload?: object): void {
    this._logAnnaEvent(eventType, payload);
  }

  experimentEvent(experimentName: string, toggle: boolean): void {
    this.event("experiment", {
      name: experimentName,
      variant: toggle ? "Treatment" : "Control",
    });
  }

  googleEvent(eventType: string): void {
    window.gtag("event", eventType);
  }

  private _logAnnaEvent(eventType: string, payload?: object): void {
    const effectivePayload = {
      ...payload,
      app_version: this._appVersion,
    };
    webticsCall("event", eventType, effectivePayload);
  }
}
