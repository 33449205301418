import { UnexpectedValueError } from "@anna-money/anna-web-lib";
import { formatDateYYYYMMDD } from "helpers/date";
import type { AbnFormValues } from "helpers/forms/acn/abnTypes";
import { CompanyStore } from "services/company/companyStore";
import type { CompanyBusinessActivitiesStore } from "services/companyBusinessActivities/au/companyBusinessActivitiesStore";
import { FormError } from "types/errors";

export enum AbnStepEntity {
  StartDate = "StartDate",
  BusinessActivity = "BusinessActivity",
  AgriculturalOperations = "AgriculturalOperations",
}

export class AbnStepStore {
  constructor(
    private readonly _companyStore: CompanyStore,
    private readonly _companyBusinessActivitiesStore: CompanyBusinessActivitiesStore,
  ) {}

  async updateEntity(data: AbnFormValues, entity: AbnStepEntity): Promise<void> {
    switch (entity) {
      case AbnStepEntity.StartDate:
        await this._updateAbnStartDate(data);
        break;
      case AbnStepEntity.BusinessActivity:
        await this._updateBusinessActivity(data);
        break;
      case AbnStepEntity.AgriculturalOperations:
        await this._updateAgriculturalOperations(data);
        break;
      default:
        throw new UnexpectedValueError("entity", entity);
    }
  }

  private async _updateAbnStartDate(data: AbnFormValues): Promise<void> {
    if (!data.startDate) {
      throw new FormError("No ABN start date value");
    }

    await this._companyStore.updateAbnStartDate(formatDateYYYYMMDD(data.startDate));
  }

  private async _updateAgriculturalOperations(data: AbnFormValues): Promise<void> {
    if (typeof data.isConductAgriculturalPropertyOperations === "undefined") {
      throw new FormError("No agricultural operations value");
    }

    await this._companyStore.updateAgriculturalPropertyOperations(data.isConductAgriculturalPropertyOperations);
  }

  private async _updateBusinessActivity(data: AbnFormValues): Promise<void> {
    if (!data.businessActivities?.length) {
      throw new FormError("No business activities value");
    }

    const mainBusinessActivity = data.businessActivities[0];

    if (!mainBusinessActivity.address) {
      throw new FormError("No business activity address value");
    }

    await this._companyBusinessActivitiesStore.updateActivity(
      mainBusinessActivity.code,
      mainBusinessActivity.description,
      mainBusinessActivity.address,
    );
  }
}
