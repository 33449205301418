import { addressDataToForm } from "helpers/forms/address";
import type { CompanyBusinessActivityData } from "services/companyBusinessActivities/gb/companyBusinessActivitiesTypes";
import type { BusinessActivityFormValues } from "./businessActivityTypes";

const companyBusinessActivityDataToFormValue = (
  companyBusinessActivity: CompanyBusinessActivityData,
): BusinessActivityFormValues => {
  return {
    id: companyBusinessActivity.businessActivityId,
    code: companyBusinessActivity.code,
    description: companyBusinessActivity.description,
    address: companyBusinessActivity.address ? addressDataToForm(companyBusinessActivity.address) : undefined,
  };
};

export const getBusinessActivitiesFormValues = (
  businessActivities?: CompanyBusinessActivityData[],
): BusinessActivityFormValues[] | undefined => {
  return businessActivities ? businessActivities.map(companyBusinessActivityDataToFormValue) : undefined;
};
