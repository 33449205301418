import type { BusinessActivitiesClient } from "services/businessActivities/businessActivitiesClient";
import { type BusinessActivitiesStore } from "services/businessActivities/businessActivitiesStore";
import type { BusinessActivityData } from "services/businessActivities/businessActivitiesTypes";
import { BusinessActivitiesSelectStoreBase } from "services/businessActivitiesSelect/businessActivitiesSelectStoreBase";
import type { CompanyStore } from "services/company/companyStore";

export class BusinessActivitiesSelectStore extends BusinessActivitiesSelectStoreBase {
  constructor(
    protected readonly _businessActivitiesClient: BusinessActivitiesClient,
    protected readonly _businessActivitiesStore: BusinessActivitiesStore,
    private readonly _companyStore: CompanyStore,
  ) {
    super(_businessActivitiesClient, _businessActivitiesStore);

    this._setActivities(this._businessActivitiesStore.activities);
  }

  protected _setActivities(activities: BusinessActivityData[]): void {
    const { businessActivityCode, businessActivityDescription } = this._companyStore.getCompany();
    this.activities = activities.filter(
      (activity) => !(businessActivityCode === activity.code && businessActivityDescription === activity.description),
    );
  }
}
