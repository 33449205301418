import * as Sentry from "@sentry/browser";

const patchedMethods = ["log", "warn", "error"] as const;

export function setupSentryExceptionEnhancer(): void {
  for (const method of patchedMethods) {
    const originalMethod = console[method];
    console[method] = function (...args: any): void {
      originalMethod.apply(this, args);
      let enhancement: Error | undefined;
      for (const arg of args) {
        if (arg instanceof Error) {
          if (!enhancement) {
            enhancement = new Error();
          }
          enhanceError(arg, enhancement);
        }
      }
    };
  }
}

const enhancementKey = Symbol("EnhancementKey");

function enhanceError(error: Error, enhancement: Error): void {
  Object.defineProperty(error, enhancementKey, { enumerable: false, value: enhancement });
}

function extractEnhancement(error: Error): Error | undefined {
  const enhancement = Object.getOwnPropertyDescriptor(error, enhancementKey)?.value;
  if (enhancement instanceof Error) {
    return enhancement;
  }
  return undefined;
}

export function enhanceExceptionEvent(event: Sentry.Event, hint: Sentry.EventHint): void {
  const exceptions = event.exception?.values;
  const enhancementStack =
    hint.originalException instanceof Error ? extractEnhancement(hint.originalException)?.stack : null;
  if (exceptions && enhancementStack) {
    const exception: Sentry.Exception = {
      type: "ErrorEnhancement",
      value: "Error enhancement",
      stacktrace: { frames: Sentry.defaultStackParser(enhancementStack, 1) },
    };
    exceptions.unshift(exception);
    event.tags = event.tags || {};
    event.tags.enhancement_test = true;
  }
}
