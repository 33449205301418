import { type ApiClient } from "@anna-money/anna-web-lib";

import {
  type AddressCreateData,
  AddressData,
  AddressListData,
  type AddressUpdateData,
} from "services/address/addressTypes";

export enum AddressKey {
  Company = "company_id",
  Director = "director_id_registered",
  DirectorPrevious = "director_id_registered_previous",
  BusinessActivityCompany = "business_company_id",
}

export class AddressClient {
  private readonly _apiClient: ApiClient;

  constructor(apiClient: ApiClient) {
    this._apiClient = apiClient;
  }

  async getAddress(key: AddressKey, id: string): Promise<AddressData | undefined> {
    const response = await this._apiClient.get(`api/formations/addresses?${key}=${id}`);
    const addresses = response.getData(AddressListData).addresses;

    return addresses.length ? addresses[0] : undefined;
  }

  async updateAddress(id: string, data: AddressUpdateData): Promise<AddressData> {
    const response = await this._apiClient.post(`api/formations/addresses/${id}`, data);

    return response.getData(AddressData);
  }

  async createAddress(data: AddressCreateData): Promise<AddressData> {
    const response = await this._apiClient.post("api/formations/addresses", data);

    return response.getData(AddressData);
  }
}
