import type { CompanyStore } from "services/company/companyStore";
import type { IssuesStore } from "services/issues/issuesStore";
import { QuestionsStore } from "services/questions/questionsStore";
import { AbnQuestion } from "./abnConstants";

export class AbnQuestionsStore extends QuestionsStore<AbnQuestion> {
  constructor(
    private readonly _companyStore: CompanyStore,
    protected readonly _issuesStore: IssuesStore,
  ) {
    super(AbnQuestion, _issuesStore);
  }

  protected _getRelevantQuestion(): AbnQuestion | undefined {
    const { company } = this._companyStore;

    if (!company?.abnStartDate) {
      return AbnQuestion.StartDate;
    }

    if (!company.businessActivityCode) {
      return AbnQuestion.BusinessActivity;
    }

    if (!company.isConductAgriculturalPropertyOperations) {
      return AbnQuestion.AgriculturalOperations;
    }

    return undefined;
  }
}
